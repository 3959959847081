const options: Intl.DateTimeFormatOptions = {
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
};

export function convertDate(date: string): string {
  return new Date(date).toLocaleDateString("ru-RU", options);
}

export function convertTime(date: string): string {
  return new Date(date).toLocaleTimeString("ru-RU", options);
}

export function formatTime(
  time: number,
  split: boolean = false,
): string | string[] {
  const days = Math.floor(time / (3600 * 24))
    .toString()
    .padStart(2, "0");
  const hours = Math.floor((time % (3600 * 24)) / 3600)
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((time % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const seconds = Math.floor(time % 60)
    .toString()
    .padStart(2, "0");

  return split
    ? [days, hours, minutes, seconds]
    : `${days ? `${days} дней` : ""}  ${hours}:${minutes}:${seconds}`;
}
